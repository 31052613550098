:root {
    --customRed: #82091F;
}

*{ 
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

@import url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Regular.css');
@import url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Medium.css');
@import url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-SemiBold.css');
@import url('https://cdn.jsdelivr.net/gh/repalash/gilroy-free-webfont@fonts/Gilroy-Bold.css');

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../public/fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Semibold weight */
  font-style: normal;
}

/* Set Gilroy as the default font */
body {
    /* font-family: 'Gilroy-Regular', 'Gilroy-Medium', 'Gilroy-SemiBold', 'Gilroy-Bold', sans-serif; */
    font-family: 'Gilroy', sans-serif;

}
.swiper {
  width: 100%;
  height: 100%;
}


